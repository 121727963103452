import React from 'react';
import qs from 'qs';
import moment from 'moment';
import { connect } from 'react-redux';
import { BlockTypeValue, SubTypeValue, InterruptTypeValue, MainTypeValue } from '@/src/utils/types';
import { sendBlockTrace } from '@/src/utils/sendBlockTrace';
import SearchBox from '../../../../components/Base/searchBox/SearchBox';
import {
	getSearchProps,
	getHotDistrict,
	getWorkingDays,
	getGlobalSearchCriterial,
	searchBoxOnlyDomesticSelector,
	displayClassGradeSelector,
	displayPassengerSelector,
	getMtSegListSelector,
} from '../../selector/search';
import { LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list';
import { onResearchFlightsWithFullUrl } from '../../../../actions/list/getGlobalSearchCriteria';
import converter from '../../../../sources/list/converter';
import LibHelper from '../../../../utils/lib';
import {
	validateSearchBox,
	getDoemsticSiteListPageUrl,
	getListPageUrl,
	getDistrictUrl,
} from '../../../../utils/checkValidate';
import { UbtHandler } from '../../../../ubt.v2/handler';
import { initSearchBoxUbt, SEARCH_BOX_UBT_KEY_TYPES } from '../../../../ubt.v2/searchBox';
import { getIfHoldDomesticFlightSwitch } from '../../../../sources/list/scope';
import { getIfArmySelector } from '../../result/flight/baseSelector';
import {
	genAddMtSeg,
	genDelMtSeg,
	genExchangeCity,
	genShowHotCityPicker,
	genFocusMtSecondDCity,
	genChangeDDateError,
	genChangeADateError,
	genChangeDCityError,
	genChangeACityError,
	genChangeMtACityError,
	genChangeMtDCityError,
	genChangeMtDDateError,
	genCleanMtError,
	genCleanOwRtError,
	fetchLowPrice,
	fetchLowRPrice,
	genSelectPOIItem,
} from '../../../../actions/channel/channel';

import {
	genOnSearchDateChange,
	genClearFocusNextInput,
	genActivateTabGroup,
	genChangePassengerQuantity,
	genExchangeMultiCity,
	genChangeClassGrade,
	genShowWarn,
	genCancelFocusMtSecondDCity,
} from '../../../../actions/list/search';

import {
	genSwitchToDistrictSearch,
	genChangeArrivalDistrict,
	fetchCountryLowPrice,
} from '../../../../actions/common/hotDistrict';

import { genSetSkipAutoReSearch } from '../../../../actions/list/router';
import { getSearchDateIsPassed } from '../../../../actions/common/searchDataStorageHelper';
import searchDataHelper from '../../../../constants/common/searchDataHelper';
import { fetchFlightSameCityDetectData } from '../../../../actions/common/searchBox';
import { CABIN_INTERNATIONAL, CABIN_DOMESTIC } from '../../../../constants/common/enum/common';
import addParamsToListUrl from '@/src/sources/list/converter/addParamsToListUrl';

const getClassGrade = ({ domestic, classGrade }) => {
	if (domestic) {
		return CABIN_DOMESTIC[classGrade] ? classGrade : CABIN_DOMESTIC.enums[0]['key'];
	} else {
		return CABIN_INTERNATIONAL[classGrade] ? classGrade : CABIN_INTERNATIONAL.enums[0]['key'];
	}
};
class SearchBoxContainer extends React.Component {
	constructor(props) {
		super(props);
		this.onSearchFormSubmit = this.onSearchFormSubmit.bind(this);
		this.setRtADateHide = this.setRtADateHide.bind(this);
		this.setShowMultiFlightAlertPopup = this.setShowMultiFlightAlertPopup.bind(this);
		this.rtADateOrRtDDateHasChange = false;
		this.state = {
			showRtADate: false,
			isSearchFormValidateError: false,
			showMultiFlightAlertPopup: false,
		};
	}
	setRtADateHide(hide) {
		const { rtADate } = this.props;
		this.setState({
			showRtADate: false,
		});
		if (hide && this.rtADateOrRtDDateHasChange && rtADate) {
			this.onSearchFormSubmit(null, this.props);
			this.rtADateOrRtDDateHasChange = false;
		}
	}
	onAddRtDate() {
		this.setState({
			showRtADate: true,
		});
	}

	setSearchDateIsPassedHint() {
		switch (this.props.flightWay) {
			case 'OW':
				this.props.onChangeDDateError(' ');
				this.props.onChangeADateError('日期已更新，如需调整可点击修改');
				break;
			case 'RT':
				this.props.onChangeDDateError(' ');
				this.props.onChangeADateError('日期已更新，如需调整可点击修改');
				break;
			case 'MT':
				this.props.onChangeMtDDateError(0, '日期已更新，如需调整可点击修改');
				break;
		}
		this.setState({
			isSearchFormValidateError: true,
		});
	}

	componentWillReceiveProps(nextProps) {
		let { flightWay } = this.props.flightWay;
		let { newFlightWay } = nextProps.flightWay;
		if (flightWay != newFlightWay) {
			return true;
		}
	}

	componentDidMount() {
		let {
			skipAutoReSearch,
			clearAutoReSearch,
			onChangeDDateError,
			onChangeADateError,
			onChangeMtDDateError,
			// fetchHotDistricts,
		} = this.props;

		document.body.addEventListener('keydown', (ev) => {
			// console.log('tab')
			if (skipAutoReSearch && (ev.keyCode === 9 || ev.keyCode === 13)) clearAutoReSearch();
		});

		// 清除下提示
		setTimeout(() => {
			if (!this.unmount) {
				onChangeDDateError('');
				onChangeADateError('');
				onChangeMtDDateError(0, '');
				this.setState({
					isSearchFormValidateError: false,
				});

				initSearchBoxUbt();
				// const useNewPoiSwitcher = window.GlobalSwitches.useNewPoiSwitcher;
				// !useNewPoiSwitcher && fetchHotDistricts();

				let isLastTimeSearchIsExpired = getSearchDateIsPassed() === 'true';
				if (isLastTimeSearchIsExpired) {
					setTimeout(() => {
						SEARCH_BOX_UBT_KEY_TYPES.SEARCH_BOX_INIT_UPLOAD_NOW.value.process({
							timeExpired: 1,
						});
					}, 2000);
				}

				isLastTimeSearchIsExpired && this.setSearchDateIsPassedHint();
				searchDataHelper.validateDateAndJump();
			}
		}, 4000);
	}

	componentWillUnmount() {
		this.unmount = true;
	}

	componentWillUpdate(nextProps, nextState) {
		const { skipAutoReSearch } = nextProps;
		if (skipAutoReSearch) return;

		const {
			owDDate,
			rtADate,
			flightWay,
			selectedCity,
			classGrade,
			passenger,
			isDistrictSearch,
			army,
			flightSameCityData,
			onChangeOwEndDate,
			onChangeADateError,
		} = this.props;
		const dCode = selectedCity.get('owDCity') && selectedCity.get('owDCity').get('Code');
		const aCode = selectedCity.get('owACity') && selectedCity.get('owACity').get('Code');
		let nextDCode =
			nextProps.selectedCity &&
			nextProps.selectedCity.get('owDCity') &&
			nextProps.selectedCity.get('owDCity').get('Code');
		let nextACode =
			nextProps.selectedCity &&
			nextProps.selectedCity.get('owACity') &&
			nextProps.selectedCity.get('owACity').get('Code');
		let dPoiid = selectedCity.get('owDCity') && selectedCity.get('owDCity').get('POIID');
		let aPoiid = selectedCity.get('owACity') && selectedCity.get('owACity').get('POIID');
		let nextDPoiid =
			nextProps.selectedCity &&
			nextProps.selectedCity.get('owDCity') &&
			nextProps.selectedCity.get('owDCity').get('POIID');
		let nextAPoiid =
			nextProps.selectedCity &&
			nextProps.selectedCity.get('owACity') &&
			nextProps.selectedCity.get('owACity').get('POIID');
		let dsearchType = selectedCity.get('owDCity') && selectedCity.get('owDCity').get('type');
		let asearchType = selectedCity.get('owACity') && selectedCity.get('owACity').get('type');
		let nextDsearchType =
			nextProps.selectedCity &&
			nextProps.selectedCity.get('owDCity') &&
			nextProps.selectedCity.get('owDCity').get('type');
		let nextAsearchType =
			nextProps.selectedCity &&
			nextProps.selectedCity.get('owACity') &&
			nextProps.selectedCity.get('owACity').get('type');
		// console.log('skipAutoReSearch: ' + skipAutoReSearch)
		// console.log(nextProps.owDDate ? nextProps.owDDate.format('YYYY-MM-DD') : null)

		let dCityCode =
			selectedCity && selectedCity.get('owDCity') && selectedCity.get('owDCity').get('ParentCode')
				? selectedCity && selectedCity.get('owDCity') && selectedCity.get('owDCity').get('ParentCode')
				: selectedCity && selectedCity.get('owDCity') && selectedCity.get('owDCity').get('Code');
		let aCityCode =
			selectedCity && selectedCity.get('owACity') && selectedCity.get('owACity').get('ParentCode')
				? selectedCity && selectedCity.get('owACity') && selectedCity.get('owACity').get('ParentCode')
				: selectedCity && selectedCity.get('owACity') && selectedCity.get('owACity').get('Code');

		if (flightWay == 'OW' && dCityCode === aCityCode && !flightSameCityData) {
			this.props.fetchFlightSameCityDetectData('onlineFlight', dCityCode);
		}

		if (nextProps.flightWay == 'MT' && nextProps.army != army) {
			this.onSearchFormSubmit(null, nextProps, true);
		}

		if (nextProps.flightWay == 'MT') return;

		let reSearch = false;
		// 单程情况下，修改出发时间，出发城市，到达城市，仓等，用户类型都会触发查询
		const { chiCount, adtCount, infCount } = passenger;
		if (
			nextProps.flightWay == 'OW' &&
			((owDDate && owDDate.format('YYYY-MM-DD') != nextProps.owDDate.format('YYYY-MM-DD')) ||
				flightWay == 'RT' ||
				(nextDCode && nextDCode != dCode) ||
				(nextACode && nextACode != aCode) ||
				(nextDPoiid && nextDPoiid != dPoiid) ||
				(nextAPoiid && nextAPoiid != aPoiid) ||
				(nextDsearchType && nextDsearchType != dsearchType) ||
				(nextAsearchType && nextAsearchType != asearchType) ||
				classGrade != nextProps.classGrade ||
				chiCount != nextProps.passenger.chiCount ||
				adtCount != nextProps.passenger.adtCount ||
				infCount != nextProps.passenger.infCount)
		) {
			reSearch = true;
		}

		//往返情况情况下，修改出发时间, 到达时间，出发城市，到达城市，仓等，用户类型都会触发查询
		if (
			(owDDate && owDDate.format('YYYY-MM-DD') != nextProps.owDDate.format('YYYY-MM-DD')) ||
			(rtADate && nextProps.rtADate && rtADate.format('YYYY-MM-DD') != nextProps.rtADate.format('YYYY-MM-DD'))
		) {
			this.rtADateOrRtDDateHasChange = true;
		}

		// 往返程下由于日期的改变触发的查询，在返程日历关闭的回调事件里：setRtADateHide
		if (
			(nextProps.flightWay == 'RT' &&
				((nextDCode && nextDCode != dCode) ||
					(nextACode && nextACode != aCode) ||
					(nextDPoiid && nextDPoiid != dPoiid) ||
					(nextAPoiid && nextAPoiid != aPoiid) ||
					classGrade != nextProps.classGrade ||
					chiCount != nextProps.passenger.chiCount ||
					adtCount != nextProps.passenger.adtCount ||
					infCount != nextProps.passenger.infCount ||
					(owDDate && owDDate.format('YYYY-MM-DD') != nextProps.owDDate.format('YYYY-MM-DD')) ||
					(rtADate &&
						nextProps.rtADate &&
						rtADate.format('YYYY-MM-DD') != nextProps.rtADate.format('YYYY-MM-DD')) ||
					flightWay != nextProps.flightWay)) ||
			(nextProps.flightWay == 'RT' && !rtADate && nextProps.rtADate)
		) {
			reSearch = true;
		}

		// 切换到返程，未选择返程日期，并且返程日历关闭的情况
		if (nextProps.flightWay == 'RT') {
			// 切换到返程，未选择返程日期，并且返程日历关闭的情况
			if (
				flightWay == 'OW' &&
				(!nextProps.rtADate ||
					!nextProps.rtADate.isValid() ||
					moment(nextProps.owDDate).isAfter(nextProps.rtADate))
			) {
				// 切换单程往返的情况下，如果owDDate晚于aDate，自动给aDate加三天
				onChangeOwEndDate({
					date: moment(owDDate).add(3, 'days').format('YYYY-MM-DD'),
				});
			} else if (!rtADate && !nextProps.rtADate && nextState.showRtADate == false) {
				onChangeADateError('请选择返程日期');
				this.newListSendBlockTrace(SubTypeValue.MissingDate);
				if (!this.state.isSearchFormValidateError) {
					this.setState({
						isSearchFormValidateError: true,
					});
				}
				reSearch = false;
			} else if (!rtADate && !nextProps.rtADate && nextState.showRtADate && nextProps.rtADate !== rtADate) {
				onChangeADateError('');
				reSearch = false;
			}
		}

		// 其他情况,多程切换单程，往返， 切换到精选地区 ,军警伤残通道
		if (
			(flightWay == 'MT' && nextProps.flightWay == 'OW' && owDDate) ||
			(flightWay == 'MT' && nextProps.flightWay == 'RT' && rtADate) ||
			(nextProps.isDistrictSearch == true && !isDistrictSearch) ||
			nextProps.army != army
		) {
			reSearch = true;
		}
		if (reSearch) {
			this.onSearchFormSubmit(null, nextProps, true);
		}
	}

	newListSendBlockTrace = (subType) => {
		sendBlockTrace({
			blockType: subType === SubTypeValue.ChinaMultiTripOverTwo ? BlockTypeValue.Pop : BlockTypeValue.Focus,
			interruptType: InterruptTypeValue.Block,
			mainType: MainTypeValue.Search,
			subType,
		});
	};

	onSearchFormSubmit(event, props, validateRtADate) {
		let {
			canNotSearch,
			isDistrictSearch,
			owDDate,
			rtDDate,
			rtADate,
			focusMtSecondDCity,
			openHotCityPicker,
			onChangeMtDCityError,
			onChangeMtACityError,
			onChangeMtDDateError,
			onChangeDDateError,
			onChangeADateError,
			onChangeDCityError,
			onChangeACityError,
			flightWay,
			selectedCity,
			arrivalDistrict,
			passenger,
			classGrade,
			getMtSegList,
			onCleanOwRtError,
			onCleanMtError,
			domestic,
			flightSameCityData,
		} = props;

		let mtSegList = getMtSegList();

		onCleanOwRtError();
		onCleanMtError();
		let { mainIsValid, isDomesticSearch } = validateSearchBox({
			canNotSearch,
			isDistrictSearch,
			selectedCity,
			flightWay,
			owDDate,
			rtDDate,
			rtADate,
			mtSegList,
			focusMtSecondDCity,
			openHotCityPicker,
			onChangeMtDCityError,
			onChangeMtACityError,
			onChangeMtDDateError,
			onChangeDDateError,
			onChangeADateError,
			onChangeDCityError,
			onChangeACityError,
			validateRtADate,
			domestic,
			flightSameCityData,
			setShowMultiFlightAlertPopup: this.setShowMultiFlightAlertPopup,
			sendBlockTrace: this.newListSendBlockTrace,
		});
		event && event.preventDefault();
		if (!mainIsValid) {
			this.setState({
				isSearchFormValidateError: true,
			});
			return;
		}
		this.setState({
			isSearchFormValidateError: false,
		});
		let locationHref = '',
			holdDomesticFlightSwitch = getIfHoldDomesticFlightSwitch(); // 是否使用新版搜索国内航班
		if (flightWay != 'MT' && isDistrictSearch) {
			locationHref = getDistrictUrl({
				owDDate,
				rtADate,
				flightWay,
				selectedCity,
				arrivalDistrict,
				rtDDate,
			});
			window.location.href = locationHref;
		} else {
			locationHref = '';
			if (holdDomesticFlightSwitch || !domestic) {
				// 兼容国内国际不同的classGrade，不兼容的情况（比如从国际的经济超级经济舱航线 切换到国内航线，国内没有经济超经济舱，这时候就要默认不限仓等）
				let newClassGrade = getClassGrade({ domestic: domestic, classGrade: classGrade });

				locationHref = getListPageUrl({
					owDDate,
					rtADate,
					flightWay,
					selectedCity,
					mtSegList,
					rtDDate,
					passenger,
					classGrade: newClassGrade,
				});
			} else {
				locationHref = getDoemsticSiteListPageUrl({
					owDDate,
					rtADate,
					flightWay,
					selectedCity,
					mtSegList,
					rtDDate,
					passenger,
					classGrade,
				});
			}
			if (converter.getListUrlParamByName('openjawcitysequence')) {
				if (locationHref.split('?')[0].indexOf(window.location.pathname) >= 0) {
					let searchParams = qs.parse(locationHref.split('?')[1]);
					if (
						searchParams.child === '0' &&
						searchParams.infant === '0' &&
						searchParams.cabin === qs.parse(window.location.search.substr(1)).cabin
					) {
						locationHref = addParamsToListUrl(
							{
								openjawcitysequence: parseInt(converter.getListUrlParamByName('openjawcitysequence')),
							},
							locationHref,
						);
					}
				}
			}
			// 保持自由组合搜索条件
			if (qs.parse(location.search.substr(1)).isbuildup === '1' && flightWay == 'RT' && rtADate) {
				locationHref = locationHref + '&isbuildup=1';
			}

			//重新搜索时，保持地图页传过来的url参数
			try {
				if (flightWay == 'OW' || flightWay == 'RT') {
					let dCityCode = '',
						aCityCode = '';
					if (flightWay == 'OW') {
						dCityCode = selectedCity.get('owDCity').get('Code');
						aCityCode = selectedCity.get('owACity').get('Code');
					} else {
						dCityCode = selectedCity.get('rtDCity').get('Code');
						aCityCode = selectedCity.get('rtACity').get('Code');
					}
					const mapPriceQueryInfo = LibHelper.getMapPriceQueryInfoFromUrl();
					if (
						mapPriceQueryInfo &&
						mapPriceQueryInfo.sourcedata.dCode == dCityCode &&
						mapPriceQueryInfo.sourcedata.aCode == aCityCode
					) {
						mapPriceQueryInfo.sourcedata = JSON.stringify(mapPriceQueryInfo.sourcedata);
						locationHref += `${locationHref.indexOf('?') >= 0 ? '&' : '?'}${qs.stringify(
							mapPriceQueryInfo,
						)}`;
					}
				}
			} catch (e) {
				console && console.error('地图跳转列表页参数获取失败！');
			}
			let { adtCount, chiCount, infCount } = passenger;
			LIST_UBT_KEY_TYPES.SEARCH_DATA_SUBMIT.value.process({
				selectedCity,
				classGrade,
				adtCount,
				chiCount,
				infCount,
				flightWay,
				owDDate,
				rtDDate,
				rtADate,
				isDistrictSearch,
				arrivalDistrict,
			});
			LIST_UBT_KEY_TYPES.SEARCH_DATA_STATISTICS_SUBMIT.value.process('submit');

			// 搜索国内且没有打开国内新版开关
			if (isDomesticSearch && !holdDomesticFlightSwitch) {
				UbtHandler.onUploadData('c_search_city_is_domestic', {
					url: locationHref,
				});
				window.location.href = locationHref;
			} else {
				onResearchFlightsWithFullUrl(locationHref, { newSkipAutoReSearch: false });
			}
		}
	}
	setShowMultiFlightAlertPopup = (show) => {
		this.setState({
			showMultiFlightAlertPopup: show,
		});
	};

	render() {
		let { flightWay } = this.props;
		return (
			<div
				className={`page-list-search-form wrapper page-list-search-form-v2 ${
					this.state.isSearchFormValidateError ? 'form-error-v4' : ''
				}`}
			>
				<SearchBox
					{...this.props}
					onAddRtDate={this.onAddRtDate.bind(this)}
					onSearchFormSubmit={this.onSearchFormSubmit}
					showSubmitBtn={flightWay === 'MT'}
					autoFocus={false}
					setRtADateHide={this.setRtADateHide}
					showRtADate={this.state.showRtADate}
					setShowMultiFlightAlertPopup={this.setShowMultiFlightAlertPopup}
					showMultiFlightAlertPopup={this.state.showMultiFlightAlertPopup}
				/>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	let searchProps = getSearchProps(state);

	const selectedCity = searchProps.get('selectedCity');
	const flightWay = searchProps.get('flightWay');
	const isShowSearchHistoryList = searchProps.get('isShowSearchHistoryList');
	const owDDate = searchProps.get('owDDate');
	const owADate = searchProps.get('owADate');
	const rtDDate = searchProps.get('rtDDate');
	let rtADate = searchProps.get('rtADate');
	const flightSameCityData = searchProps.get('flightSameCityData');

	const dDateError = searchProps.get('dDateError');
	const aDateError = searchProps.get('aDateError');
	const dCityError = searchProps.get('dCityError');
	const aCityError = searchProps.get('aCityError');
	const mtError = searchProps.get('mtError');
	const showCityPicker = searchProps.get('showCityPicker');
	const showValidateErrorTip = searchProps.get('showValidateErrorTip');
	const showValidateWarnTip = searchProps.get('showValidateWarnTip');
	let searchHistory = searchProps.get('searchHistory');
	const focusNextInput = searchProps.get('focusNextInput');
	const lowPrice = searchProps.get('lowPrice');
	const lowRPrice = searchProps.get('lowRPrice');
	const lowestPrice = searchProps.get('lowestPrice');
	const lowestRPrice = searchProps.get('lowestRPrice');
	const hotDistrict = getHotDistrict(state);
	const isDistrictSearch = hotDistrict.get('isDistrictSearch');
	const arrivalDistrict = hotDistrict.get('arrivalDistrict');
	const hotDistrictList = hotDistrict.get('hotDistrictList');
	const countryLowestPrice = searchProps.get('countryLowestPrice');
	const countryLowPrice = searchProps.get('countryLowPrice');
	const countryLowestRPrice = searchProps.get('countryLowestRPrice');
	const countryLowRPrice = searchProps.get('countryLowRPrice');
	const isNeedFocusSecond = searchProps.get('isNeedFocusSecond');
	const skipAutoReSearch = searchProps.get('skipAutoReSearch');

	return {
		flightWay: flightWay,
		selectedCity: selectedCity,
		rtDDate: rtDDate,
		owDDate: owDDate,
		rtADate: rtADate,
		passenger: displayPassengerSelector(state),
		classGrade: displayClassGradeSelector(state),
		isDistrictSearch: isDistrictSearch,
		arrivalDistrict: arrivalDistrict,
		dDateError: dDateError,
		aDateError: aDateError,
		dCityError: dCityError,
		aCityError: aCityError,
		mtError: mtError,
		showCityPicker: showCityPicker,
		showValidateErrorTip: showValidateErrorTip,
		focusNextInput: focusNextInput,
		showValidateWarnTip: showValidateWarnTip,
		searchHistory: searchHistory,
		lowPrice: lowPrice,
		lowRPrice: lowRPrice,
		lowestPrice: lowestPrice,
		lowestRPrice: lowestRPrice,
		workingDays: getWorkingDays(state),
		hotDistrictList: hotDistrictList,
		countryLowestPrice: countryLowestPrice,
		countryLowPrice: countryLowPrice,
		countryLowestRPrice: countryLowestRPrice,
		countryLowRPrice: countryLowRPrice,
		priceMapSwitch: true,
		isShowSearchHistoryList: isShowSearchHistoryList,
		owADate: owADate,
		getMtSegList: getMtSegListSelector(state),
		isNeedFocusSecond: isNeedFocusSecond,
		showOwAndRtValidateErrorTip: true,
		globalSearchCriteria: getGlobalSearchCriterial(state),
		skipAutoReSearch,
		domestic: searchBoxOnlyDomesticSelector(state),
		army: getIfArmySelector(state),
		flightSameCityData: flightSameCityData,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	selectFlightWay(data) {
		LIST_UBT_KEY_TYPES.SEARCH_DATA_STATISTICS_SUBMIT.value.process('flightWay');
		dispatch(genActivateTabGroup(data, 'flightWay', 'search'));
	},
	onCleanOwRtError() {
		dispatch(genCleanOwRtError());
	},
	onCleanMtError() {
		dispatch(genCleanMtError());
	},
	onChangeDCityError(error) {
		dispatch(genChangeDCityError(error));
	},
	onChangeACityError(error) {
		dispatch(genChangeACityError(error));
	},
	onChangeDDateError(error) {
		dispatch(genChangeDDateError(error));
	},

	onChangeADateError(error) {
		dispatch(genChangeADateError(error));
	},
	onChangeMtDCityError(index, error) {
		dispatch(genChangeMtDCityError(index, error, 'search'));
	},
	onChangeMtACityError(index, error) {
		dispatch(genChangeMtACityError(index, error, 'search'));
	},
	onChangeMtDDateError(index, error) {
		dispatch(genChangeMtDDateError(index, error, 'search'));
	},
	//focus第二程
	focusMtSecondDCity() {
		dispatch(genFocusMtSecondDCity('search'));
	},
	//打开热门城市搜索器（在校验城市相同时打开用）
	openHotCityPicker(name) {
		dispatch(genShowHotCityPicker(name));
	},
	// fetchHotDistricts() {
	// 	fetchHotDistricts(dispatch);
	// },
	//选择舱等
	onClassGradeChange(classGrade) {
		LIST_UBT_KEY_TYPES.SEARCH_DATA_STATISTICS_SUBMIT.value.process('classGrade');
		dispatch(genChangeClassGrade(classGrade, ownProps.domestic));
	},
	onSubmitPassengerTypeCount(passenger) {
		dispatch(genChangePassengerQuantity(passenger, ownProps.domestic));
	},

	onChangeOwStartDate(m, focusNextInput) {
		SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_SELECT.value.process({
			tag: { type: 'selectDate', calendarType: 'depart', value: moment(m.date).format('YYYY-MM-DD') },
		});
		dispatch(genOnSearchDateChange('owDDate', moment(m.date), focusNextInput, 'search'));
	},

	onChangeOwEndDate(m) {
		SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_SELECT.value.process({
			tag: { type: 'selectDate', calendarType: 'arrive', value: moment(m.date).format('YYYY-MM-DD') },
		});
		dispatch(genOnSearchDateChange('rtADate', moment(m.date), false, 'search'));
	},
	onChangeMtStartDate(index, m) {
		SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_SELECT.value.process({
			tag: { type: 'selectDate', calendarType: 'mtDepart', value: moment(m.date).format('YYYY-MM-DD') },
		});
		dispatch(genOnSearchDateChange('mtStartDate' + (index + 1), moment(m.date), false, 'search'));
	},

	exchangeCity() {
		let synToMt = true;
		dispatch(genExchangeCity(synToMt));
	},
	clearFocusNextInput() {
		dispatch(genClearFocusNextInput());
	},
	//日历低价
	fetchLowPrice(dCityCode, aCityCode, options) {
		dispatch(fetchLowPrice(dCityCode, aCityCode, options));
	},
	//返程低价日历
	fetchLowRPrice(flightSegmentList, cabin, flightWay, domestic) {
		dispatch(fetchLowRPrice(flightSegmentList, cabin, flightWay, domestic));
	},
	//热门国家
	clearPoiItem(name) {
		dispatch(genSelectPOIItem(name, null, 'search'));
	},
	changeToDistrictSwitch(isDistrictSearch) {
		dispatch(genSwitchToDistrictSearch(isDistrictSearch));
	},
	changeArrivalDistrict(arrivalDistrict) {
		dispatch(genChangeArrivalDistrict(arrivalDistrict));
	},
	// onCityFocus() {
	// 	dispatch(fetPriceMapSwitch('priceMapSwitch'));
	// },

	fetchCountryLowPrice(dCityCode, aCountryCode, flightWay) {
		dispatch(fetchCountryLowPrice(dCityCode, aCountryCode, flightWay));
	},

	onAddMtSeg() {
		dispatch(genAddMtSeg('search'));
	},
	onDelMtSeg(i) {
		dispatch(genDelMtSeg(+i, 'search'));
	},
	exchangeMtCity(index) {
		let synToOw = true;
		dispatch(genExchangeMultiCity(+index, 'search', synToOw));
	},
	changePoi(name, item, focusNextInput) {
		LIST_UBT_KEY_TYPES.SEARCH_DATA_STATISTICS_SUBMIT.value.process(name);
		dispatch(genSelectPOIItem(name, item, 'search', focusNextInput));
	},
	showWarn() {
		dispatch(genShowWarn());
	},
	onDistrictError(error, dCityError) {
		// +++
		dispatch(genChangeACityError(error));
		dispatch(genChangeDCityError(dCityError));
	},
	cancelFocusMtSecondDCity() {
		dispatch(genCancelFocusMtSecondDCity());
	},

	clickHotDistrict(hotDistrict) {
		dispatch(genChangeArrivalDistrict(hotDistrict));
		dispatch(genSwitchToDistrictSearch(true));
	},
	clearAutoReSearch() {
		dispatch(genSetSkipAutoReSearch(false));
	},

	fetchFlightSameCityDetectData(source, cityCode) {
		dispatch(fetchFlightSameCityDetectData(source, cityCode));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBoxContainer);
